// import "./App.css";
import TrackScreen from "./views/TrackScreen";
import bgImage from "./Images/a3_lab_splash_image.webp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import AlbumScreen from "./views/AlbumScreen";
import AlbumPlayLinkScreen from "./views/AlbumPlayLinkScreen";
import HomeScreen from "./views/HomeScreen";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#03595a",
      light: "#9dc0c9",
    },
    background: {
      main: "#2a5e6c",
      primary: "#0c8089",
      default: "#f3f9fb",
      paper: "#ffffff",
      light: "#d6ebf0",
      grey: "#797676",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      mobile: 480,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
  typography: {
    fontFamily: "BookAntiqua",
  },
  shadows: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
});

export const apiUrl = "https://dashboardapi.a3labels.in";
export const a3TunesDomainUrl = "https://a3tunes.com";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route exact element={<HomeScreen />} path="/" />
            <Route exact element={<TrackScreen />} path="/:id" />
            <Route exact element={<AlbumScreen />} path="/album/:id" />
            <Route
              exact
              element={<AlbumPlayLinkScreen />}
              path="/albumlink/:id"
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;

import { Box, Grid, Typography, Backdrop } from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { theme, apiUrl, a3TunesDomainUrl } from "../App";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function AlbumScreen() {
  const [loading, setLoading] = useState(false);
  const [albumError, setAlbumError] = useState(null);
  const [albumResponse, setAlbumResponse] = useState(null);

  useEffect(() => {
    const inputString = window.location.pathname;
    const trimmedString = inputString.trim(); // Remove leading and trailing spaces
    const parts = trimmedString.split("/album/");

    setLoading(true);
    fetchAlbumDetailsResponse(parts[1])
      .then((data) => {
        setAlbumResponse(data);
        setLoading(false);
      })
      .catch((error) => {
        setAlbumError(error);
        setLoading(false);
      });
  }, []);

  async function fetchAlbumDetailsResponse(albumTitle) {
    try {
      const response = await fetch(
        `${apiUrl}/musiclabels_album_details/${albumTitle}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        setAlbumError(data);
      }
    } catch (error) {
      throw error;
    }
  }

  return (
    <Box
      sx={{
        maxWidth: 900,
        padding: "50px",
        boxSizing: "border-box",
        margin: "auto",
      }}
    >
      {albumResponse && (
        <Box>
          <Backdrop
            sx={{
              backdropFilter: "blur(5px)", // Adjust the blur value as needed
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust the background color and opacity as needed
              zIndex: -1, // Ensure the backdrop is above the dialog content
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${albumResponse?.release_picture})`, // Replace with the URL of your image
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: `100%`,
                width: `100%`,
                opacity: 0.1, // Adjust the image opacity as needed
              },
            }}
            open={true}
          />
          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <img
                src={albumResponse?.release_picture}
                alt={"title"}
                onError={() => {
                  // Error handling code goes here
                }}
                style={{
                  width: "250px",
                  aspectRatio: "1/1",
                  overflow: "hidden",
                  boxShadow: "1px 1px 1px 1px ",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                marginLeft: "30px",
              }}
            >
              <Typography
                sx={{ color: theme.palette.primary.main }}
                component={"div"}
              >
                Album
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 32, sm: 40, md: 48, lg: 56 },
                  color: theme.palette.primary.main,
                }}
                component={"div"}
              >
                {albumResponse?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: theme.palette.primary.main,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                  component={"span"}
                >
                  {albumResponse?.artists}
                </Typography>{" "}
                • {albumResponse?.production_year}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            sx={{
              marginY: 5,
            }}
          >
            {albumResponse?.tracks?.length > 0 &&
              albumResponse.tracks.map((track, index) => (
                <Typography
                  component={"a"}
                  href={`${a3TunesDomainUrl}/${track.title}`}
                  target="_blank"
                  sx={{
                    width: "100%",
                    padding: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textDecoration: "none",
                    color: theme.palette.primary.main,
                    boxShadow: theme.shadows,
                    backgroundColor: theme.palette.background.default,
                    borderRadius: 2,
                    margin: 0.5,

                    "&:hover": {
                      backgroundColor: theme.palette.background.light,
                      cursor: "pointer",
                      transition: "transform 0.2s, color 0.2s",
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <Typography component={"span"}>{index + 1}</Typography>
                    <Box>
                      <Typography component={"span"}>{track?.title}</Typography>
                      <br />
                      <Typography
                        style={{ color: theme.palette.primary.light }}
                        component={"span"}
                      >
                        {track.primary_artists ? "By " : ""}
                        <Typography component={"span"}>
                          {track.primary_artists}
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <ArrowForwardIcon color={theme.palette.primary.main} />
                  </Box>
                </Typography>
              ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default AlbumScreen;

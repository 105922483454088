import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ImageIcon from "@mui/icons-material/Image";
import CircularProgress from "@mui/material/CircularProgress";
// All Platform Image imports
import {
  AmazonMusic,
  Anghami,
  AppleMusic,
  Deezer,
  Gaana,
  JioSaavn,
  Spotify,
  WynkMusic,
  YouTube,
  YouTubeMusic,
  logo,
  facebook,
  instagram,
  aiplex_x,
  linkedin,
  a3_logo,
  aiplex,
  insta_icon,
  facebook_icon,
  youtube_icon,
  linked_in_icon,
  aiplex_x_icon,
  Pandora,
  Qobuzz,
  SoundCloud,
  Resso,
  iTunes,
  Instagram,
  Ami,
  Aspiro,
  SevenDigital,
  Music24_7,
  AudibleMagic,
  AudioMack,
  BmatMusic,
  BoomPlay,
  Facebook,
  Fizy,
  Genie,
  Gracenote,
  iMusica,
  Joox,
  KKBox,
  Kuack,
  LangitMusik,
  MediaNet,
  MillwardBrown,
  MoodMedia,
  Moov,
  Muud,
  NaverMusic,
  Netease,
  NhnBugs,
  PCMusic,
  Phononet,
  Qishui,
  QQMusic,
  Recochoku,
  Rhapsody,
  Stellar,
  TelecomItalia,
  TikTok,
  TouchTunes,
  Trebel,
  VerveLife,
  Zing,
  AmazonMusicSmall,
  SpotifySmall,
  AnghamiSmall,
  Music24_7Small,
  AppleMusicSmall,
  DeezerSmall,
  GaanaSmall,
  JioSaavnSmall,
  WynkMusicSmall,
  RessoSmall,
  YoutubeSmall,
  sharenowMobile,
  Qobuz_small,
  Pandora_small,
  itunes_small,
  sound_clound_small,
  instagram_small,
  AmiSmall,
  SevenDigitalSmall,
  AspiroSmall,
  AudibleMagicSmall,
  AudioMackSmall,
  AWASmall,
  BmatMusicSmall,
  BoomPlaySmall,
  DreamusSmall,
  FacebookSmall,
  FizySmall,
  GenieSmall,
  GracenoteSmall,
  HungamaSmall,
  iMusicaSmall,
  JaxstaSmall,
  JooxSmall,
  KKBoxSmall,
  KuackSmall,
  LangitMusikSmall,
  MediaNetSmall,
  MillwardBrownSmall,
  MoodMediaSmall,
  MoovSmall,
  MuudSmall,
  NaverMusicSmall,
  NeteaseSmall,
  NhnBugsSmall,
  PCMusicSmall,
  PhononetSmall,
  QishuiSmall,
  QQMusicSmall,
  RecochokuSmall,
  RhapsodySmall,
  SlackerSmall,
  StellarSmall,
  TelecomItaliaSmall,
  TikTokSmall,
  TouchTunesSmall,
  TrebelSmall,
  VerveLifeSmall,
  YoutubeMusicSmall,
  ZingSmall,
  bgImage,
} from "./PlatformImages";

import { theme, apiUrl, a3TunesDomainUrl } from "../App";

function TrackScreen() {
  const [trackResponse, setTrackResponse] = useState(null);
  const [trackError, setTrackError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      setInnerWidth(width);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let inputString = window.location.pathname;
    const trimmedString = inputString.trim(); // Remove leading and trailing spaces
    // const replacedString = trimmedString.replace(/ |%20/g, "-");
    const parts = trimmedString.split(/(?<!-)\b--(?!-)/);
    const lastPart = parts[parts.length - 1];
    const truncatedString = parts.slice(0, parts.length - 1);

    let path =
      parts.length > 1
        ? `${truncatedString.join("-")}?-=${lastPart}`
        : `${parts[0]}`;

    if (path?.includes("--")) {
      const [firstPart, secondPart] = path.split("--");
      path = `${firstPart}?-=${secondPart}`;
    }

    setLoading(true);
    fetchTrackDetailsResponse(path)
      .then((data) => {
        if (data?.status === "Failed") {
          setTrackError(data);
          setLoading(false);
        } else {
          setTrackResponse(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setTrackError(error);
        setLoading(false);
      });
  }, []);

  async function fetchTrackDetailsResponse(trackId) {
    try {
      const response = await fetch(
        `${apiUrl}/musiclabels_track_details${trackId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        setTrackError(data);
      }
    } catch (error) {
      throw error;
    }
  }

  // Desktop Images
  function ReturnPlatformLogoUrl(platform_id) {
    switch (platform_id) {
      case "1":
        return Spotify;
      case "2":
        return AppleMusic;
      case "3":
        return YouTubeMusic;
      case "4":
        return AmazonMusic;
      case "5":
        return JioSaavn;
      case "6":
        return Anghami;
      case "7":
        return Deezer;
      case "8":
        return Music24_7;
      case "9":
        return SevenDigital;
      case "10":
        return Ami;
      case "11":
        return Aspiro;
      case "12":
        return AudibleMagic;
      case "13":
        return AudioMack;
      case "15":
        return BmatMusic;
      case "16":
        return BoomPlay;
      case "18":
        return Facebook;
      case "19":
        return Fizy;
      case "20":
        return Genie;
      case "21":
        return Gracenote;
      case "23":
        return iMusica;
      case "24":
        return iTunes;
      case "26":
        return Joox;
      case "28":
        return KKBox;
      case "29":
        return Kuack;
      case "30":
        return LangitMusik;
      case "31":
        return MediaNet;
      case "32":
        return MillwardBrown;
      case "33":
        return MoodMedia;
      case "34":
        return Moov;
      case "35":
        return Muud;
      case "37":
        return NaverMusic;
      case "38":
        return Netease;
      case "39":
        return NhnBugs;
      case "41":
        return Pandora;
      case "42":
        return PCMusic;
      case "43":
        return Phononet;
      case "44":
        return Qishui;
      case "45":
        return Qobuzz;
      case "46":
        return QQMusic;
      case "47":
        return Recochoku;
      case "48":
        return Resso;
      case "49":
        return Rhapsody;
      case "53":
      case "54":
      case "55":
        return SoundCloud;
      case "56":
        return Stellar;
      case "57":
        return TelecomItalia;
      case "59":
        return TikTok;
      case "61":
        return TouchTunes;
      case "62":
        return Trebel;
      case "64":
        return VerveLife;
      case "65":
        return WynkMusic;
      case "66":
        return Zing;
      case "80":
        return Instagram;
      case "81":
      case "82":
      case "83":
      case "youtube":
        return YouTube;
      default:
        return "";
    }
  }

  // Mobile Images
  function ReturnSmallsizePlatformLogoUrl(platform_id) {
    switch (platform_id) {
      case "1":
        return SpotifySmall;
      case "2":
        return AppleMusicSmall;
      case "3":
        return YoutubeMusicSmall;
      case "4":
        return AmazonMusicSmall;
      case "5":
        return JioSaavnSmall;
      case "6":
        return AnghamiSmall;
      case "7":
        return DeezerSmall;
      case "8":
        return Music24_7Small;
      case "9":
        return SevenDigitalSmall;
      case "10":
        return AmiSmall;
      case "11":
        return AspiroSmall;
      case "12":
        return AudibleMagicSmall;
      case "13":
        return AudioMackSmall;
      case "14":
        return AWASmall;
      case "15":
        return BmatMusicSmall;
      case "16":
        return BoomPlaySmall;
      case "17":
        return DreamusSmall;
      case "18":
        return FacebookSmall;
      case "19":
        return FizySmall;
      case "20":
        return GenieSmall;
      case "21":
        return GracenoteSmall;
      case "22":
        return HungamaSmall;
      case "23":
        return iMusicaSmall;
      case "24":
        return itunes_small;
      case "25":
        return JaxstaSmall;
      case "26":
        return JooxSmall;
      case "28":
        return KKBoxSmall;
      case "29":
        return KuackSmall;
      case "30":
        return LangitMusikSmall;
      case "31":
        return MediaNetSmall;
      case "32":
        return MillwardBrownSmall;
      case "33":
        return MoodMediaSmall;
      case "34":
        return MoovSmall;
      case "35":
        return MuudSmall;
      case "37":
        return NaverMusicSmall;
      case "38":
        return NeteaseSmall;
      case "39":
        return NhnBugsSmall;
      case "41":
        return Pandora_small;
      case "42":
        return PCMusicSmall;
      case "43":
        return PhononetSmall;
      case "44":
        return QishuiSmall;
      case "45":
        return Qobuz_small;
      case "46":
        return QQMusicSmall;
      case "47":
        return RecochokuSmall;
      case "48":
        return RessoSmall;
      case "49":
        return RhapsodySmall;
      case "52":
        return SlackerSmall;
      case "53":
      case "54":
      case "55":
        return sound_clound_small;
      case "56":
        return StellarSmall;
      case "57":
        return TelecomItaliaSmall;
      case "59":
        return TikTokSmall;
      case "61":
        return TouchTunesSmall;
      case "62":
        return TrebelSmall;
      case "64":
        return VerveLifeSmall;
      case "65":
        return WynkMusicSmall;
      case "66":
        return ZingSmall;
      case "80":
        return instagram_small;
      case "81":
      case "82":
      case "83":
      case "youtube":
        return YoutubeSmall;
      default:
        return "";
    }
  }

  function RenderImage(binaryData) {
    try {
      // Assuming release_picture_base64 is your Base64 encoded string
      const release_picture_base64 = binaryData;

      // Convert Base64 to Blob
      const byteCharacters = atob(release_picture_base64);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "image/jpeg" }); // Adjust the MIME type as needed

      // Do something with the Blob, like sending it to a server or displaying it
      let imgUrl = URL.createObjectURL(blob);

      return imgUrl;
    } catch (error) {
      return error;
    }
  }

  const twitterButtonRef = useRef();
  const facebookButtonRef = useRef();
  const linkedInButtonRef = useRef();
  const instagramButtonRef = useRef();

  useEffect(() => {
    const link = encodeURI(window.location.href);

    let twitter_link = `https://twitter.com/intent/tweet?url=${link}&via=a3tunes.com&related=A3Tunes`;

    let instagram_link = `https://www.instagram.com/p/${link}/`;

    const linkedIn_link = `https://www.linkedin.com/share?url=${encodeURIComponent(
      link
    )}`;

    twitterButtonRef.current &&
      (twitterButtonRef.current.href = encodeURI(twitter_link));

    instagramButtonRef.current &&
      (instagramButtonRef.current.href = encodeURI(instagram_link));

    linkedInButtonRef.current &&
      (linkedInButtonRef.current.href = encodeURI(linkedIn_link));
  });

  const returnSortedPlaylinks = (responseplatforms) => {
    let platforms = ["1", "2", "48", "65", "5", "4", "22"];

    // let sortenplatforms = responseplatforms?.sort((a, b) => {
    //   const indexA = platforms.indexOf(a.streaming_platform_id);
    //   const indexB = platforms.indexOf(b.streaming_platform_id);

    //   // If a's platform id is not in platforms array, place it after all platforms
    //   if (indexA === -1) {
    //     return 1;
    //   }
    //   // If b's platform id is not in platforms array, place it after all platforms
    //   else if (indexB === -1) {
    //     return -1;
    //   }

    //   return indexA - indexB; // Normal sorting if both ids are in platforms array
    // });

    responseplatforms?.sort((a, b) => {
      return a.order_number - b.order_number;
    });

    return responseplatforms;
  };

  const [errorDisplay, setErrorDisplay] = useState(true);

  function isImageURLValid(url) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        // If the image loaded successfully, resolve with true
        resolve(true);
      };
      img.onerror = () => {
        // If there was an error loading the image, resolve with false
        resolve(false);
      };
    });
  }

  let [validurl, setvalidurl] = useState(true);

  if (trackResponse) {
    isImageURLValid(trackResponse?.release_picture).then((isValid) => {
      if (isValid) {
        setvalidurl(true);
      } else {
        setvalidurl(false);
      }
    });
  }

  return (
    <>
      <div style={{ position: "relative", width: "100%" }}>
        <img
          src={bgImage}
          alt=""
          style={{
            width: "100%",
            height: "95vh",
            objectFit: "contain",
            overflow: "hidden",
          }}
        />
        {loading ? (
          <Dialog
            open={true}
            // fullWidth
            maxWidth={"xs"}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <DialogContent>
                <CircularProgress color="success" />
              </DialogContent>
            </Box>
          </Dialog>
        ) : trackError ? (
          trackError.message && (
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={errorDisplay}
              autoHideDuration={1500}
              onClose={() => setErrorDisplay(false)}
              TransitionComponent={Slide}
            >
              <Alert
                onClose={() => setErrorDisplay(false)}
                severity="warning"
                sx={{
                  width: "100%",
                  borderRadius: "5px",
                }}
              >
                {trackError.message}
              </Alert>
            </Snackbar>
          )
        ) : (
          <Dialog
            open={true}
            fullWidth
            maxWidth={"lg"}
            sx={{
              left: 0,
            }}
          >
            <DialogContent>
              <Grid container item lg={12} md={12} sm={12} gap={1}>
                <Grid
                  container
                  item
                  lg={3}
                  md={3}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xs: "center", md: "flex-start" },
                    }}
                  >
                    {trackResponse?.release_picture && validurl ? (
                      <img
                        src={trackResponse.release_picture}
                        alt={trackResponse?.track_title}
                        onError={() => {
                          // Error handling code goes here
                        }}
                        style={{
                          width: "200px",
                          height: "200px",
                          overflow: "hidden",
                          // borderRadius: 10,
                          boxShadow: "1px 1px 1px 1px ",
                        }}
                      />
                    ) : (
                      <ImageIcon
                        style={{
                          width: "200px",
                          height: "200px",
                          overflow: "hidden",
                          // borderRadius: 10,
                          // boxShadow: "1px 1px 1px 1px ",
                        }}
                      />
                    )}
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "20px",
                        textAlign: {
                          xs: "center",
                          md: "start",
                          mobile: "center",
                        },
                      }}
                    >
                      <>
                        <Typography
                          component={"h1"}
                          sx={{
                            fontSize: "22px",
                            color: "#03595a",
                          }}
                        >
                          {trackResponse?.track_title}
                        </Typography>
                      </>
                    </Typography>
                    <Typography
                      component={"span"}
                      sx={{
                        fontSize: "18px",
                        textAlign: { sm: "center", md: "start" }, //"start"
                      }}
                    >
                      {trackResponse?.artists?.length > 0 &&
                        trackResponse.artists.map((artist, index) => (
                          <>
                            <Typography
                              component={"h2"}
                              sx={{
                                fontSize: "20px",
                                color: "#bebec0",
                                // fontWeight: 800,
                              }}
                            >
                              {artist.artist_name}
                            </Typography>
                          </>
                        ))}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  lg={8.9}
                  md={8.8}
                  sm={12}
                  mobile={12}
                  xs={12}
                >
                  {/* images and play */}
                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: { xs: 1, sm: 2, md: 3, lg: 4 },
                    }}
                  >
                    {trackResponse?.play_link.length > 0 &&
                      returnSortedPlaylinks(trackResponse.play_link) &&
                      returnSortedPlaylinks(trackResponse.play_link).length !==
                        0 &&
                      returnSortedPlaylinks(trackResponse.play_link).map(
                        (link) => (
                          <Grid
                            item
                            lg={2}
                            md={3}
                            sm={4}
                            xs={12}
                            sx={{
                              flexBasis: "20%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <a
                              href={link.play_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                alignItems: "center",
                                display: "flex",
                                color: "black",
                              }}
                            >
                              <Box
                                style={{
                                  width: {
                                    lg: "165px",
                                    md: "150px",
                                    xs: "120px",
                                  },
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection:
                                    window.innerWidth <= 480 ? "row" : "column",
                                }}
                              >
                                <Box
                                  style={{
                                    overflow: "hidden",
                                    padding: 5,
                                  }}
                                >
                                  {window.innerWidth <= 480 ? (
                                    <img
                                      src={ReturnSmallsizePlatformLogoUrl(
                                        link?.streaming_platform_id
                                      )}
                                      alt={
                                        link?.streaming_plfatform_name
                                          ? link.streaming_plfatform_name
                                          : "Preview"
                                      }
                                      onError={() => {}}
                                      style={{
                                        height: "56px",
                                        width: "184px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={ReturnPlatformLogoUrl(
                                        link?.streaming_platform_id
                                      )}
                                      alt={
                                        link?.streaming_plfatform_name
                                          ? link.streaming_plfatform_name
                                          : "Preview"
                                      }
                                      onError={() => {}}
                                      style={{
                                        height: "165px",
                                        width: "142px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                </Box>

                                <Button
                                  size="small"
                                  sx={{
                                    height: "50px",
                                    width: "100%",
                                    borderRadius: "50px",
                                    fontSize: "18px",
                                    border: "1px solid #80808033",
                                    color: "grey",
                                    marginY: 1,
                                    boxShadow: "0px 0px 1px 0px grey",
                                    textTransform: "initial",
                                    // display: { mobile: "none", sm: "block" },
                                    display:
                                      window.innerWidth <= 480
                                        ? "none"
                                        : "block",
                                  }}
                                >
                                  Play
                                </Button>
                                <Button
                                  size="small"
                                  sx={{
                                    color: "#87c9ce",
                                    textTransform: "initial",
                                    // display: { mobile: "block", sm: "none" },
                                    display:
                                      window.innerWidth <= 480
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <PlayCircleOutlineIcon
                                    sx={{
                                      fontSize: "36px",
                                    }}
                                  />
                                </Button>
                              </Box>
                            </a>
                          </Grid>
                        )
                      )}
                  </Grid>
                  <br />

                  {/* social  */}

                  <Grid
                    container
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    sx={{
                      display: "flex",
                      paddingY: 5,
                      justifyContent: {
                        lg: "space-between",
                      },
                    }}
                  >
                    <Grid
                      container
                      item
                      lg={4}
                      md={12}
                      sm={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // paddingY: 2,
                        alignItems: {
                          xs: "center",
                          lg: "start",
                        },
                      }}
                    >
                      {window.innerWidth <= 480 ? (
                        <img
                          style={{ height: "28px" }}
                          src={sharenowMobile}
                        ></img>
                      ) : (
                        <Box
                          paddingY={2}
                          sx={{ fontSize: "29px", color: "grey" }}
                        >
                          Share Now
                        </Box>
                      )}

                      <Box sx={{ display: "flex", gap: 3, marginTop: "25px" }}>
                        <img
                          style={{ height: "40px", cursor: "pointer" }}
                          src={facebook}
                          alt={"Facebook Logo"}
                          onClick={() => {
                            const shareUrl = `https://www.facebook.com/dialog/share?app_id=1486797298503754&display=popup&e2e=%7B%7D&fallback_redirect_uri=${
                              window.location.href
                            }&href=${encodeURIComponent(
                              window.location.href
                            )}&locale=en_US&next=${
                              window.location.href
                            }&sdk=joeyyes`;

                            window.open(shareUrl);
                          }}
                        ></img>

                        {/* <img
                        style={{ height: "40px", cursor: "pointer" }}
                        src={linkedin}
                        onClick={() => {
                          let post_data = {
                            author: "urn:li:person:8675309",
                            lifecycleState: "PUBLISHED",
                            specificContent: {
                              "com.linkedin.ugc.ShareContent": {
                                shareCommentary: {
                                  text: "Test link sharing",
                                },
                                shareMediaCategory: "ARTICLE",
                                media: [
                                  {
                                    status: "READY",
                                    description: {
                                      text: "Test Title",
                                    },
                                    originalUrl:
                                      "https://testurl.com/",
                                    title: {
                                      text: "Test link share",
                                    },
                                  },
                                ],
                              },
                            },
                            visibility: {
                              "com.linkedin.ugc.MemberNetworkVisibility":
                                "PUBLIC",
                            },
                          };

                          let linkedIn_post_url = `https://api.linkedin.com/v2/ugcPosts`;
                          fetch(linkedIn_post_url, {
                            method: "POST",

                            body: post_data,
                          })
                            .then((res) => {
                              return res.json();
                            })
                            .then((response) => {
                              console.log("RESPONSE_LINKED", response);
                            });

                          // window.open(shareUrl);
                        }}
                      ></img> */}

                        {/* <a ref={linkedInButtonRef} target="_blank">
                        <img style={{ height: "40px" }} src={linkedin}></img>
                      </a> */}
                        <a ref={twitterButtonRef} target="_blank">
                          <img
                            style={{ height: "40px", cursor: "pointer" }}
                            src={aiplex_x}
                            alt={"Twitter Logo"}
                          ></img>
                        </a>
                        {/* <a ref={instagramButtonRef} target="_blank">
                        <img
                          style={{ height: "40px", cursor: "pointer" }}
                          src={instagram}
                        ></img>
                      </a> */}
                      </Box>
                    </Grid>

                    {/* laptop view  */}
                    <Grid
                      container
                      item
                      lg={6}
                      md={12}
                      sm={12}
                      sx={{
                        paddingY: 8,
                        display: {
                          md: "flex",
                          xs: "none",
                        },
                        justifyContent: "space-between",
                        alignItems: {
                          xs: "center",
                          md: "center",
                        },
                      }}
                    >
                      {/* laptop view  */}
                      <Box
                        sx={{
                          justifyContent: "space-around",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                        }}
                      >
                        <Box>
                          <img
                            style={{ height: "50px", cursor: "pointer" }}
                            src={a3_logo}
                            alt={"A3Labels Logo"}
                            onClick={() => {
                              window.open("https://a3labels.in/");
                            }}
                          ></img>
                        </Box>

                        <Box
                          sx={{
                            height: "100%",
                            width: "2px",
                            borderLeft: "1px solid #80808033",
                          }}
                        ></Box>

                        <Box>
                          <Typography
                            sx={{
                              fontSize: "10px",
                              fontWeight: 700,
                              p: 0,
                              m: 0,
                            }}
                          >
                            Powered By
                          </Typography>
                          <img
                            style={{
                              height: "30px",
                              cursor: "pointer",
                            }}
                            src={aiplex}
                            alt={"Aiplex Logo"}
                            onClick={() => {
                              window.open("https://www.aiplex.com");
                            }}
                          ></img>
                        </Box>

                        <Box
                          sx={{
                            height: "100%",
                            width: "2px",
                            borderLeft: "1px solid #80808033",
                          }}
                        ></Box>

                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 3 }}>
                            <Typography style={{ height: "27px" }}>
                              <Typography sx={{ fontSize: "11px" }}>
                                Follow
                              </Typography>
                              <Typography sx={{ fontSize: "11px" }}>
                                us on
                              </Typography>
                            </Typography>
                            <img
                              style={{ height: "27px", cursor: "pointer" }}
                              src={insta_icon}
                              alt={"a3-instagram-social-media"}
                              onClick={() => {
                                window.open(
                                  "https://www.instagram.com/a3labels/"
                                );
                              }}
                            ></img>
                            <img
                              style={{ height: "27px", cursor: "pointer" }}
                              src={facebook_icon}
                              alt={"a3-facebook-social-media"}
                              onClick={() => {
                                window.open(
                                  "https://www.facebook.com/A3Labels"
                                );
                              }}
                            ></img>
                          </Box>

                          <Box sx={{ display: "flex", gap: 3 }}>
                            <img
                              style={{
                                height: "24px",
                                width: "34px",
                                cursor: "pointer",
                              }}
                              src={youtube_icon}
                              alt={"a3-youtube-social-media"}
                              onClick={() => {
                                window.open(
                                  "https://www.youtube.com/@a3labels"
                                );
                              }}
                            ></img>
                            <img
                              style={{ height: "27px", cursor: "pointer" }}
                              src={linked_in_icon}
                              alt={"a3-linkedin-social-media"}
                              onClick={() => {
                                window.open(
                                  "https://www.linkedin.com/company/a3labels/"
                                );
                              }}
                            ></img>
                            <img
                              style={{ height: "27px", cursor: "pointer" }}
                              src={aiplex_x_icon}
                              alt={"a3-twitter-social-media"}
                              onClick={() => {
                                window.open("https://twitter.com/a3labels");
                              }}
                            ></img>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    {/* mobile view  */}

                    <Grid
                      container
                      item
                      lg={4}
                      md={12}
                      sm={12}
                      paddingY={3}
                      sx={{
                        display: {
                          md: "none",
                          xs: "flex",
                        },
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: {
                          xs: "center",
                          lg: "start",
                        },
                      }}
                    >
                      <Box>
                        <img
                          style={{ height: "60px", cursor: "pointer" }}
                          src={a3_logo}
                          alt={"A3Labels Logo"}
                          onClick={() => {
                            window.open("https://a3labels.in/");
                          }}
                        ></img>
                      </Box>

                      <Box
                        sx={{
                          height: "100%",
                          width: "1px",
                          borderLeft: "1px solid #80808033",
                        }}
                      ></Box>

                      <Box>
                        <Typography
                          sx={{ fontSize: "10px", fontWeight: 700, p: 0, m: 0 }}
                        >
                          Powered By
                        </Typography>
                        <img
                          style={{ height: "40px", cursor: "pointer" }}
                          src={aiplex}
                          alt={"Aiplex Logo"}
                          onClick={() => {
                            window.open("https://www.aiplex.com");
                          }}
                        ></img>
                      </Box>
                    </Grid>

                    <Grid
                      container
                      item
                      lg={4}
                      md={12}
                      sm={12}
                      sx={{
                        display: {
                          md: "none",
                          xs: "flex",
                        },
                        flexDirection: "column",
                        alignItems: {
                          xs: "center",
                          lg: "start",
                        },
                      }}
                    >
                      <Box
                        paddingY={2}
                        sx={{
                          fontFamily: "BookAntiqua",
                          color: "#7d7d81",
                        }}
                      >
                        Follow Us On
                      </Box>
                      <Box
                        sx={{ display: "flex", gap: 3, alignItems: "center" }}
                      >
                        <img
                          style={{ height: "27px", cursor: "pointer" }}
                          src={insta_icon}
                          alt={"a3-instagram-social-media"}
                          onClick={() => {
                            window.open("https://www.instagram.com/a3labels/");
                          }}
                        ></img>
                        <img
                          style={{ height: "27px", cursor: "pointer" }}
                          src={facebook_icon}
                          alt={"a3-facebook-social-media"}
                          onClick={() => {
                            window.open("https://www.facebook.com/A3Labels");
                          }}
                        ></img>
                        <img
                          width={"34px"}
                          height={"24px"}
                          src={youtube_icon}
                          alt={"a3-youtube-social-media"}
                          onClick={() => {
                            window.open("https://www.youtube.com/@a3labels");
                          }}
                        ></img>
                        <img
                          style={{ height: "27px", cursor: "pointer" }}
                          src={linked_in_icon}
                          alt={"a3-linkedin-social-media"}
                          onClick={() => {
                            window.open(
                              "https://www.linkedin.com/company/a3labels/"
                            );
                          }}
                        ></img>
                        <img
                          style={{ height: "27px", cursor: "pointer" }}
                          alt={"a3-twitter-social-media"}
                          src={aiplex_x_icon}
                          onClick={() => {
                            window.open("https://twitter.com/a3labels");
                          }}
                        ></img>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default TrackScreen;

import React from "react";
import { bgImage } from "./PlatformImages";
import { Box, Typography } from "@mui/material";
import AIPLEXLOGO from "../Images/AIPLEXLOGO.webp";

function HomeScreen() {
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <img
        src={bgImage}
        alt=""
        style={{
          width: "100%",
          height: "95vh",
          objectFit: "contain",
          overflow: "hidden",
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          position: "absolute",
          bottom: 0,
          flexDirection: "column",
        }}
      >
        <Typography
          component={"p"}
          color={"#2a5e6c"}
          sx={{ fontFamily: "BookAntiqua", textAlign: "center" }}
        >
          A3tunes is a brand name of A3 Labels
        </Typography>

        <Box>
          <a href="https://www.aiplex.com/" target={"blank"}>
            <img
              src={AIPLEXLOGO}
              alt="A3Labels"
              style={{
                background: "white",
                padding: "2px",
                width: "67px",
                height: "29px",
              }}
            />
          </a>
        </Box>
      </Box>
    </div>
  );
}

export default HomeScreen;
